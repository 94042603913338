@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* VARIABLES */
:root {
  --main-bg-color-dark: #212529;
  --main-text-color-dark: #e8e8e8;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Montserrat', sans-serif;
  background-color: #212529;
  background-color: var(--main-bg-color-dark);
  color: #e8e8e8;
  color: var(--main-text-color-dark);
  background-color: #1d1d1d;
}
.page {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.heading {
  font-size: 45px;
}
.highlight {
  color: #7f78d2;
}
.countdown-wrapper {
  max-width: 800px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 40px 0;
}
.countdown-box {
  background-color: #413c69;
  font-size: 50px;
  font-weight: 700;
  color: #dcd6f7;
  border-radius: 15px;
  width: 160px;
  height: 160px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.legend {
  font-size: 30px;
  color: #a6b1e1;
}
.wish-message {
  font-size: 45px;
  font-weight: 700;
}
.birthdate {
  font-size: 25px;
  font-weight: 600;
  color: #7f78d2;
}
.credits {
  margin-top: 15px;
}
.github-logo {
  opacity: 0.5;
  width: 50px;
}
.github-logo:hover {
  opacity: 1;
}
.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}
.form input {
  margin: 10px;
}
input {
  color: #e8e8e8;
  color: var(--main-text-color-dark);
  outline: none;
  width: 300px;
  height: 40px;
  background-color: transparent;
  border: 1px rgba(255, 255, 255, 0.2) solid;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
}
select {
  width: 300px;
  height: 40px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.527);
  font-family: Montserrat, sans-serif;
  border: 1px rgba(255, 255, 255, 0.164) solid;
  padding-left: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 5px;
  font-size: 18px;
  margin-top: 10px;
  outline: none;
}
input:focus,
select:focus,
.btn:focus {
  border: 1px rgba(255, 255, 255, 1) solid;
  transition: all 0.5s;
}
.btn {
  width: 300px;
  height: 40px;
  margin-top: 20px;
  outline: none;
  border: 1px rgba(255, 255, 255, 0.2) solid;
  background-color: transparent;
  border-radius: 5px;
  color: rgba(255, 255, 255, 0.719);
  font-size: 18px;
  font-weight: 700;
}
.btn:hover {
  color: white;
}
a {
  color: #7f78d2;
  font-weight: bold;
  margin-top: 20px;
}
.gen-link {
  margin-top: 20px;
  font-size: 400;
}

